<template>
<el-dialog v-model="visible" :title="title" :before-close="close" custom-class="crc-transfer-dialog">
  <div class="ctd-line row-center-start" v-loading="loading">
    <div class="ctdl-title">用户姓名：</div>
    <el-select v-model="form.t_crc_id" @change="handleChange">
      <el-option v-for="(item, index) in crcOption" :key="index" :label="item.txt_crc_name" :value="item.t_crc_id">
        <span style="float: left">{{ item.txt_crc_name }}</span>
        <span style="float: right; color: var(--el-text-color-secondary); font-size: 13px;">
          {{ item.txt_login_name }}
        </span>
      </el-option>
    </el-select>
  </div>
  <div class="ctd-line row-center-start">
    <div class="ctdl-title">登录名：</div>
    <div class="font-bold">{{ form.txt_login_name|| "- -" }}</div>
  </div>
  <div class="ctd-line row-center-start">
    <div class="ctdl-title">创建时间：</div>
    <div>{{ form.dt_create || "- -" }}</div>
  </div>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close(false)" type="info">取 消</el-button>
      <el-button @click="handleSubmit()" type="primary">确 认</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// crc - 移交 dialog
import { useStore } from "vuex";
import { getTransferCRC } from "api/apis.js";
import { reactive, toRefs, computed } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
  props: {
    dialogData: { type: Object }
  },
  setup(prop, ctx) {
    const store = useStore();
    const state = reactive({
      title: "移交至",
      visible: true,
      crcOption: computed(() => {
        let target = store.getters.crcs.filter(t => t.is_disabled == 1),
          index = target.findIndex(t => t.t_crc_id == prop.dialogData.t_crc_id);
        target.splice(index, 1);
        return target;
      }),
      form: {
        t_crc_id: "",
        txt_login_name: "",
        dt_create: "",
        txt_crc_name: ""
      },
      loading: false
    })

    const close = () => {
      ctx.emit("close");
    }

    // 提交事件
    const handleSubmit = () => {
      ElMessageBox.confirm(`是否将【${prop.dialogData.txt_crc_name}】名下数据移交至【${state.form.txt_crc_name}】？`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消"
      }).then(() => {
        state.loading = true;
        getTransferCRC({
          t_source_crc_id: prop.dialogData.t_crc_id,
          t_target_crc_id: state.form.t_crc_id
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("操作成功");
            close();
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.loading = false;
        })
      })
    };

    // 选择器切换事件
    const handleChange = e => {
      let target = state.crcOption.filter(t => t.t_crc_id == e)[0];
      state.form.txt_login_name = target.txt_login_name;
      state.form.dt_create = target.dt_create;
      state.form.txt_crc_name = target.txt_crc_name;
    };

    return {
      ...toRefs(state),
      close,
      handleChange,
      handleSubmit
    }
  }
}
</script>

<style lang="scss">
.crc-transfer-dialog {
  width: 650px !important;
  height: 50%;

  .el-dialog__body {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ctd-line {
      margin: 14px 20px;
      width: 60%;

      .ctdl-title {
        width: 40%;
      }

      .font-bold {
        font-weight: bold;
      }
    }
  }

  .dialog-footer {
    .el-button {
      border-radius: 16px;

      &:last-child {
        border-color: #8a88c3;
        background-color: #8a88c3;
      }
    }
  }
}
</style>
